import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`On Trying to Become A Youtuber`}</h1>
    <h2>{`TL;DR`}</h2>
    <p>{`I want to become a youtuber to fill the missing void of good Filipino programmer/developer youtubers and bad crypto gurus that promotes ponzis and scams.`}</p>
    <h2>{`Not TL;DR`}</h2>
    <p>{`Ok, so why in the first place? Well after all the crap I've seen my fellow countrymen do I feel like I can do a much better job than most of them. Maybe not at explaining things but much better when it comes to the substance of the content I will make (and maybe explaining things to them). `}</p>
    <p>{`There is a reason why no there is no famous Filipino youtuber. They all kinda sucked and if there is one that did not suck, please do tell me! I believe I haven't seen a single one of them make a joke, not that I watch any of them. I mean if there is one, I would have known by now! Why do I got to know first `}<a parentName="p" {...{
        "href": "https://www.youtube.com/c/BenAwad97"
      }}>{`Ben Awad`}</a>{` and `}<a parentName="p" {...{
        "href": "https://www.youtube.com/c/TechLead"
      }}>{`Techlead`}</a>{` (Now let's not get into other issues here)`}</p>
    <p>{`And while I was writing this post, I've also asked my friends who is a good filipino developer / youtuber and there is none. I feel like there is an opportunity here.`}</p>
    <p>{`Not only from being a developer / youtuber, most of the crypto experts on the Philippines sucks as well. I believe I'm the only one who is doing research deep dive research on these one. Maybe I can make a crypto content about IDOs. Nobody made a content about GoldFinch finance yet even though I think its one of the good projects in the cryptospace there is.`}</p>
    <p>{`Should I? Or Will I?`}</p>
    <h2>{`Problems`}</h2>
    <p>{`But what happens when you put a very awkward guy in front of a camera. He'll be more awkward and he'll say a lot of uuuuuhhhh and ummmm. Which totally sucks (When I'm talking about myself). But when I watch PewDiePie's first videos, MrBeast first videos, and Atty Libayan's first videos, it always start very awkward. Maybe I should make my youtube debut and make one video a month about something. Maybe I should start with my crypto research and not plan too much ahead. I believe I should at least try, yeah?`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      